<template>
  <div class="exhibition">
    <div class="exhibition__wrapper">
      <div
        class="exhibition__background"
        :style="{
          backgroundImage: `url(${exhibition.header_image})`,
        }"
      ></div>
      <div class="exhibition__content">
        <div class="exhibition__details exhibition__row">
          <div class="exhibition__details-box exhibition__column exhibition__column--three">
            <h2 class="exhibition__details-box_title">{{ exhibition.title }}</h2>
            <div
              class="exhibition__details-box_details collect-editor collect-editor--white"
              v-html="exhibition.description"
            ></div>
            <BaseButton
              v-if="exhibition.button_url"
              class="button button--xs button--outline-white button--uppercase"
              :text="exhibition.button_text || 'click to go'"
              @click="changeLocation(exhibition.button_url)"
            />
          </div>
          <div class="exhibition__info-box exhibition__column exhibition__column--one">
            <template v-if="exhibition.type === 'Online'">
              <span>
                EXHIBITION
                {{
                  exhibitionEnd
                    ? "HAS ENDED:"
                    : exhibitionStart
                    ? "IS ENDING ON:"
                    : "IS OPENING ON:"
                }}
              </span>
              <span class="exhibition__info-box_time">
                {{
                  exhibitionEnd
                    ? $moment(exhibition.end_date).format("MMMM Do")
                    : exhibitionStart
                    ? $moment(exhibition.end_date).format("MMMM Do")
                    : $moment(exhibition.start_date).format("MMMM Do")
                }}
              </span>
              <!-- <span v-else class="exhibition__info-box_end-date">
                Event ended on {{ $moment(exhibition.end_date).format("MMMM Do") }}
              </span> -->
            </template>
            <template v-else>
              <span>
                EXHIBITION
                {{
                  exhibitionEnd
                    ? "HAS ENDED:"
                    : exhibitionStart
                    ? "IS ENDING IN:"
                    : "IS OPENING IN:"
                }}
              </span>
              <span v-if="time !== '00d:00h:00m:00s'" class="exhibition__info-box_time">
                {{ time }}
              </span>
              <span v-else class="exhibition__info-box_end-date">
                Event ended on {{ $moment(exhibition.end_date).format("MMMM Do") }}
              </span>
            </template>
            <div class="exhibition__info-box_buttons">
              <BaseButton
                v-if="exhibition.button_rsvp_url"
                class="button button--xs button--white"
                text="RSVP"
                @click="changeLocation(exhibition.button_rsvp_url)"
              />
              <BaseButton
                v-if="exhibition.catalog_button_url"
                class="button button--xs button--outline-white button--uppercase"
                :text="exhibition.catalog_button_text || 'catalog'"
                @click="changeLocation(exhibition.catalog_button_url)"
              />
            </div>
            <li class="exhibition__info-box_section"><span>EXHIBITION INFO</span></li>
            <div class="exhibition__info-box_info">
              <span>{{ exhibition.type }}</span>
              <div v-html="exhibition.schedule" class="collect-editor collect-editor--white"></div>
              <span>{{ exhibition.address }}</span>
            </div>
            <template v-if="exhibition.safety_protocol">
              <li class="exhibition__info-box_section"><span>SAFETY PROTOCOL</span></li>
              <div
                v-html="exhibition.safety_protocol_info"
                class="collect-editor collect-editor--white"
              ></div>
            </template>
          </div>
        </div>
        <div class="exhibition__details_video" v-if="exhibition.videos && exhibition.videos.url">
          <CustomVideo :src="exhibition.videos.url" />
          <p v-if="exhibition.videos.caption">{{ exhibition.videos.caption }}</p>
        </div>
        <template v-if="feature">
          <div class="exhibition__title-box">
            <h2 class="exhibition__title-box_title">Featured in exhibition</h2>
            <div class="exhibition__title-box_right">
              <div class="exhibition__title-box_right-content">
                <Tag
                  class="tag--public"
                  :class="{ 'tag--selected': cardData.key === i.id }"
                  v-for="i in tagsData"
                  :key="i.id"
                  :value="i.title"
                  @click="changeSort(i.id)"
                />
              </div>
              <div class="divider divider--vertical"></div>
              <div class="exhibition__title-box_right-content">
                <PublicSelect
                  v-if="cardData.key === 'art'"
                  style="min-width: 190px"
                  :items="sortDataArts"
                  :selected="cardData.sort"
                  @input="sortExhibition"
                ></PublicSelect>
                <PublicSelect
                  style="min-width: 190px"
                  v-else
                  :items="sortDataArtists"
                  :selected="cardData.sort"
                  @input="sortExhibition"
                ></PublicSelect>
                <FilterBlock
                  style="margin-left: 15px"
                  :items="filters"
                  @open="openFilters = $event"
                  @selectFilter="selectFilter"
                  @apply="refreshSelectedFilters"
                  @clear="refreshSelectedFilters(true)"
                ></FilterBlock>
              </div>
            </div>
          </div>

          <div v-if="features.length > 0" class="exhibition__exhibitions-box">
            <div
              class="exhibition__exhibitions-box_card"
              v-for="(item, key) in features"
              :key="`${key}-item`"
              @click="goToFeature(item.slug)"
            >
              <div class="image-box">
                <img class="image" :src="item.search_image" />
                <!-- <div v-if="item.sku" class="code">{{ item.sku }}</div> -->
                <div class="hover-container">Click to View</div>
              </div>
              <h3 class="title">{{ item.title || item.name }}</h3>
              <!-- <span class="price">{{ item.price }}</span> -->
              <span class="subtitle">{{ item.artist || item.region }}</span>
            </div>
          </div>
          <h6 class="exhibition__empty" v-else>No {{ cardData.key }}</h6>

          <div class="exhibition__button-box" v-if="hasMoreFeatures">
            <BaseButton
              class="button button--lg button--white"
              text="LOAD MORE"
              @click.native="loadMore"
            />
          </div>
        </template>

        <div class="exhibition__catalog-box">
          <div class="exhibition__catalog-box_container">
            <h2 class="exhibition__catalog-box_title" v-if="exhibition.catalog_title">
              {{ exhibition.catalog_title }}
            </h2>
            <div
              class="collect-editor collect-editor--white exhibition__catalog-box_details"
              v-if="exhibition.catalog_description"
              v-html="exhibition.catalog_description"
            ></div>
            <BaseButton
              v-if="exhibition.catalog_button_text"
              class="button button--xs button--white button--uppercase"
              :text="exhibition.catalog_button_text"
              @click="changeLocation(exhibition.catalog_button_url)"
            />
          </div>
          <div
            v-if="exhibition.catalog_image"
            class="exhibition__catalog-box_image"
            :style="{
              backgroundImage: `url(${exhibition.catalog_image})`,
            }"
          ></div>
        </div>

        <div class="exhibition__sponsors-box exhibition__row">
          <div
            class="exhibition__sponsors-box_sponsors exhibition__column exhibition__column--three"
            v-if="exhibition.sponsors.length > 0"
          >
            <h3 class="title">Sponsors</h3>
            <div class="container">
              <a
                v-for="(item, key) in exhibition.sponsors"
                :key="`sponsor-${key}`"
                class="container__item sponsor-link"
                :class="{ 'sponsor-link--no-clickable': !item.url }"
                :href="item.url ? item.url : ''"
                target="_blank"
                @click.prevent="changeLocation(item.url)"
              >
                <span class="container__item_box">
                  <img class="container__item_logo" :src="item.image" alt="Sponsor logo"
                /></span>
                <span>{{ item.caption }}</span>
              </a>
            </div>
          </div>
          <div class="exhibition__sponsors-box_contacts exhibition__column exhibition__column--one">
            <h3 class="title">Contacts</h3>
            <div v-for="(item, key) in contacts" :key="`contact-${key}`" class="contact-item">
              <span class="item-title">{{ item.title }}: </span>
              <a class="item-contact" :href="item.href">{{ item.contact }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/buttons/BaseButton.vue";
import PublicSelect from "@/components/inputs/PublicSelect.vue";
import Tag from "@/components/Tag.vue";
import FilterBlock from "@/components/FilterPublic.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import CustomVideo from "@/components/CustomVideo.vue";

export default {
  components: { BaseButton, PublicSelect, Tag, FilterBlock, CustomVideo },

  data() {
    return {
      time: "00d:00h:00m:00s",
      timer: null,
      exhibitionStart: false,
      exhibitionEnd: false,
      tagsData: [
        { id: "art", title: "Art" },
        { id: "artist", title: "Artists" },
      ],
      sortDataArts: [
        { id: "title_a_z", title: "Art (A-Z)" },
        { id: "title_z_a", title: "Art(Z-A)" },
        { id: "last_name_a_z", title: "Artist (A-Z)" },
        { id: "last_name_z_a", title: "Artist (Z-A)" },
      ],
      sortDataArtists: [
        { id: "last_name_a_z", title: "Artist (A-Z)" },
        { id: "last_name_z_a", title: "Artist (Z-A)" },
      ],
      cardData: {
        slug: null,
        materials: "",
        price_start: "",
        price_end: "",
        colors: "",
        region: "",
        sort: "title_a_z",
        page: 1,
        key: "art",
      },
      openFilters: false,
      contacts: [
        { title: "email", contact: "art@browngrotta.com", href: "mailto:art@browngrotta.com" },
        { title: "phone", contact: "203-834-0623", href: "tel:2038340623" },
        { title: "fax", contact: "203-762-5981", href: "fax:2037625981" },
      ],
    };
  },
  head: {
    title: function () {
      return {
        inner: this.exhibition.title ?? "Exhibition browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: `${this.exhibition.title ?? "Exhibition browngrotta arts"} - ${
            this.exhibition.description ?? "Description Exhibition browngrotta arts"
          }`,
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: this.exhibition.title ?? "Exhibition browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content:
            this.exhibition.header_image ??
            `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: this.exhibition.title ?? "Exhibition browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content:
            this.exhibition.header_image ??
            `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: this.exhibition.title ?? "Exhibition browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: this.exhibition.description ?? "Description Exhibition browngrotta arts",
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: this.exhibition.title ?? "Exhibition browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: this.exhibition.description ?? "Description Exhibition browngrotta arts",
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content:
            this.exhibition.header_image ??
            `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: this.exhibition.title ?? "Exhibition browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },
  computed: {
    ...mapGetters("publicapi/exhibitions", {
      exhibition: "getExhibition",
      feature: "getFeature",
      features: "getFeatures",
      hasMoreFeatures: "getHasMoreFeatures",
    }),
    ...mapGetters("publicapi/filters", {
      filters: "getFilters",
      selectedFilters: "getSelectedFilters",
    }),
  },
  async created() {
    this.cardData.slug = this.$route.params.slug;
    await this.apiGetSpecificExhibition(this.$route.params.slug);
    this.$emit("updateHead");
    await this.getItemsForFilters(this.cardData.key);
    await this.apiGetFeatures(this.cardData);
    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Exhibitions",
        to: "/exhibitions",
        clickable: true,
      },
      {
        title: `${this.exhibition.title}`,
        clickable: false,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
    /* this.contactsCreate(); */

    this.countDown();
    this.timer = setInterval(() => this.countDown(), 1000);

    this.setShareData({
      title: this.exhibition.title,
      description: this.exhibition.description,
      image: this.exhibition.header_image,
    });
  },
  destroyed() {
    if (this.timer != null) {
      clearInterval(this.timer);
    }
  },
  methods: {
    ...mapMutations(["setBreadcrumbs"]),
    ...mapMutations("publicapi/share", ["setShareData"]),
    ...mapActions("publicapi/exhibitions", ["apiGetSpecificExhibition", "apiGetFeatures"]),
    ...mapActions("publicapi/filters", ["apiGetFiltersBlock"]),
    ...mapMutations("publicapi/filters", ["updateFilters", "updateSelectedFilters", "clear"]),
    countDown() {
      const dateFormat = "YYYY-MM-DD HH:mm:ss";

      const currentTime = this.$moment().tz("America/New_York").format(dateFormat);
      const eventStart = this.$moment(this.exhibition.start_date).format(dateFormat);
      const eventEnd = this.$moment(this.exhibition.end_date).format(dateFormat);

      if (currentTime > eventEnd) {
        this.exhibitionEnd = true;
      } else if (currentTime > eventStart) {
        this.exhibitionStart = true;
        this.contTime(eventEnd, currentTime);
      } else if (currentTime < eventStart) {
        this.exhibitionStart = false;
        this.contTime(eventStart, currentTime);
      }
    },
    contTime(start, end) {
      let seconds = this.$moment(start).diff(end, "seconds");
      if (seconds < 0) {
        seconds = 0;
      }
      let d = Math.floor(seconds / (3600 * 24));
      let h = Math.floor((seconds % (3600 * 24)) / 3600);
      let m = Math.floor((seconds % 3600) / 60);
      let s = Math.floor(seconds % 60);

      let dDisplay = d < 10 ? `0${d}` : d;
      let hDisplay = h < 10 ? `0${h}` : h;
      let mDisplay = m < 10 ? `0${m}` : m;
      let sDisplay = s < 10 ? `0${s}` : s;

      this.time = `${dDisplay}d:${hDisplay}h:${mDisplay}m:${sDisplay}s`;
    },
    async getItemsForFilters(val) {
      let filterBlock = {
        list: "materials,price,colors",
        type: "collection",
      };
      switch (val) {
        case "art": {
          await this.apiGetFiltersBlock(filterBlock);
          break;
        }
        case "artist": {
          await this.apiGetFiltersBlock({ ...filterBlock, list: "regions" });
          break;
        }
      }
    },
    /* contactsCreate() {
      this.contacts.forEach(el => {
        switch (el.title) {
          case "email": {
            el.contact = process.env.VUE_APP_OWNER_EMAIL;
            el.href = `mailto:${process.env.VUE_APP_OWNER_EMAIL}`;
            break;
          }
          case "phone": {
            el.contact = process.env.VUE_APP_OWNER_PHONE;
            el.href = `tel:${process.env.VUE_APP_OWNER_PHONE}`;
            break;
          }
          case "fax": {
            el.contact = process.env.VUE_APP_OWNER_FAX;
            el.href = `fax:${process.env.VUE_APP_OWNER_FAX}`;
            break;
          }
        }
      });
    }, */
    async changeSort(id) {
      let data = { ...this.cardData, key: id };
      await this.getItemsForFilters(id);
      switch (id) {
        case "art": {
          data = { ...data, sort: "title_a_z" };
          break;
        }
        case "artist": {
          data = { ...data, sort: "last_name_a_z" };
          break;
        }
      }
      let success = await this.apiGetFeatures(data);
      if (success) {
        this.cardData = { ...data };
      }
    },
    async sortExhibition({ id }) {
      let data = { ...this.cardData, sort: id };
      let success = await this.apiGetFeatures(data);
      if (success) {
        this.cardData = { ...data };
      }
    },
    selectFilter(val) {
      this.updateFilters(val);
      if (val.apply) {
        this.refreshSelectedFilters();
      }
    },
    async refreshSelectedFilters(clear) {
      if (clear) {
        await this.clear();
      }
      await this.updateSelectedFilters();
      this.filterTable();
    },
    async filterTable() {
      let data = { ...this.cardData, page: 1 };
      this.selectedFilters.forEach(el => {
        if (el.key === "price") {
          data.price_start = "";
          data.price_end = "";
          el.items.forEach(i => {
            data[i.key] = i.val;
          });
        } else {
          let filters = el.items.map(i => {
            return i.id;
          });
          data[el.key] = filters.join(",");
        }
      });
      let success = await this.apiGetFeatures(data);
      if (success) {
        this.cardData = data;
      }
    },
    async loadMore() {
      if (this.hasMorePages) {
        let data = { ...this.cardData, page: this.cardData.page + 1 };
        let success = await this.apiGetFeatures(data);
        if (success) {
          this.cardData = data;
        }
      }
    },
    goToFeature(slug) {
      let url = this.cardData.key === "art" ? `/artworks/${slug}` : `/artists/${slug}`;
      this.$router.push({ path: url });
    },
    changeLocation(url) {
      if (url) window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
  font-size: 18px;
  line-height: 1.55;
  @media screen and (max-width: $xs) {
    font-size: 16px;
  }
}

.exhibition {
  display: flex;

  &__wrapper {
    position: relative;
    min-height: 100vh;
    min-width: 100vw;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 58.4rem;
    z-index: 0;
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    @media screen and (max-width: $xs) {
      height: 33.5rem;
    }
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba($color: $lightBlack, $alpha: 0.45);
      z-index: 1;
    }
    &::after {
      content: "";
      bottom: -1px;
      position: absolute;
      width: 100%;
      height: 50%;
      background: linear-gradient(
        0deg,
        rgba($color: $lightBlack, $alpha: 1) 0%,
        rgba($color: $lightBlack, $alpha: 0) 100%
      );
      z-index: 1;
    }
  }

  &__content {
    position: relative;
    width: 100%;
    margin-top: 40rem;
    padding: 0rem 13.7rem 7.4rem;
    z-index: 2;
    @media screen and (max-width: $md) {
      margin-top: 24.5rem;
      padding: 0rem 8rem 7.4rem;
    }
    @media screen and (max-width: $sm) {
      padding: 0rem 3rem 7.4rem;
    }
    @media screen and (max-width: $xs) {
      padding: 0rem 25px 11rem;
    }
    .exhibition__row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      &--align-center {
        align-items: center;
      }
    }
    .exhibition__column {
      &--three {
        width: 57.5%;
        @media screen and (max-width: $md) {
          width: 100%;
        }
      }
      &--one {
        width: 33%;
        @media screen and (max-width: $md) {
          width: 100%;
        }
      }
    }
  }
  &__details {
    &_video {
      padding: 80px 0;
      padding: 80px 0;
      @media screen and (max-width: $sm) {
        padding: 4rem 0;
      }
      @media screen and (max-width: $xs) {
        padding: 4rem 0;
      }
      p {
        font-size: 1.8rem;
        font-weight: 300;
        line-height: 1.66;
        color: $white;
        text-align: center;
        padding: 14px;
      }
    }
  }

  &__details-box {
    display: flex;
    flex-direction: column;
    &_title {
      font-size: 5.2rem;
      line-height: 1.55;
      margin-bottom: 30px;
      @media screen and (max-width: $md) {
        font-size: 35px;
        margin-bottom: 20px;
      }
    }

    .button {
      margin-top: 2.2rem;
      @media screen and (max-width: $xs) {
        margin-top: 3.5rem;
      }
    }
  }

  &__info-box {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 2.5rem 0rem 0rem 0;
    @media screen and (max-width: $md) {
      width: 100%;
      padding: 9.5rem 0rem 0rem 0rem;
    }

    &_buttons {
      width: 100%;
      display: flex;
      margin: 17px 0rem 30px;

      .button {
        max-width: 180px;

        &--white {
          margin-right: 1.5rem;
        }
      }
    }

    &_section {
      margin-bottom: 1rem;
    }

    &_info {
      display: flex;
      flex-direction: column;
      margin-bottom: 25px;
    }

    &_time {
      font-size: 4.85rem;
      line-height: 1.5;
      @media screen and (max-width: $md) {
        font-size: 40px;
      }
      @media screen and (max-width: $xs) {
        font-size: 35px;
      }
    }

    &_end-date {
      font-size: 36px;
      line-height: 1.3;
    }

    li {
      span {
        font-size: 16px;
        font-weight: 700;
        margin-left: -12px;
        @media not all and (min-resolution: 0.001dpcm) {
          @supports (-webkit-appearance: none) {
            margin-left: 0px;
          }
        }
        @supports (-moz-appearance: none) {
          margin-left: 0px;
        }
      }
    }
  }

  &__title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 9rem 0rem 6rem;
    @media screen and (max-width: $lg) {
      flex-direction: column;
      align-items: flex-start;
    }
    @media screen and (max-width: $xs) {
      margin: 8.5rem 0rem 2rem;
    }
    &_right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      &-content {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        & > * {
          margin-bottom: 10px;
        }
      }
      @media screen and (max-width: $lg) {
        margin-top: 30px;
        margin-left: -10px;
      }
      @media screen and (max-width: $xs) {
        width: 100%;
        flex-direction: column;
        justify-content: flex-end;
        .divider {
          display: none;
        }
        &-content {
          flex-wrap: wrap;
          &:last-of-type {
            margin-left: 15px;
          }
        }
      }
    }
    &_title {
      font-size: 5.2rem;
      line-height: 1.25;
      @media screen and (max-width: $md) {
        font-size: 35px;
      }
    }
  }
  &__empty {
    font-size: 20px;
    font-weight: 600;
    padding: 10px 10px 50px;
    text-align: center;
    text-transform: uppercase;
  }

  &__exhibitions-box {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(30%, 1fr));
    grid-column-gap: 3rem;
    grid-row-gap: 7rem;
    @media screen and (max-width: $md) {
      grid-template-columns: repeat(2, minmax(30%, 1fr));
    }
    @media screen and (max-width: $xs) {
      grid-template-columns: minmax(30%, 1fr);
      grid-row-gap: 7rem;
    }

    &_card {
      display: flex;
      flex-direction: column;
      cursor: pointer;

      &:hover {
        .image-box {
          .hover-container {
            opacity: 1;
          }
        }
      }

      .image-box {
        position: relative;

        .image {
          height: 27vw;
          width: 100%;
          object-fit: cover;
          background-size: cover;
          background-position: center;
          border-radius: 4px;
          @media screen and (max-width: $xs) {
            height: 90vw;
          }
        }

        // .code {
        //   position: absolute;
        //   right: 9px;
        //   bottom: 9px;
        //   text-transform: uppercase;
        //   border: 1px solid $white;
        //   border-radius: 5px;
        //   background-color: rgba(0, 0, 0, 0.45);
        //   min-width: 7.2rem;
        //   font-size: 1.4rem;
        //   line-height: 2.5rem;
        //   padding: 0.1rem 1rem;
        //   z-index: 2;
        //   max-width: 30%;
        //   display: block;
        //   white-space: nowrap;
        //   overflow: hidden;
        //   text-overflow: ellipsis;
        // }

        .hover-container {
          position: absolute;
          width: 100%;
          height: 100%;
          font-size: 3rem;
          line-height: 5rem;
          border-radius: 4px;
          background-color: rgba($color: $blue, $alpha: 0.83);
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease;
          opacity: 0;
          top: 0;
        }
      }

      .title {
        font-size: 2.4rem;
        line-height: 3.1rem;
        margin-top: 1rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        text-overflow: ellipsis;
        overflow: hidden;

        // &:hover {
        //   text-decoration: underline;
        // }
      }

      // .price {
      //   font-size: 1.8rem;
      //   line-height: 2.8rem;
      // }

      .subtitle {
        margin-top: 0.5rem;
        font-size: 1.4rem;
        line-height: 2.5rem;
        text-transform: uppercase;
        @media screen and (max-width: $xs) {
          margin-top: 1rem;
          font-size: 1.6rem;
          line-height: 2.8rem;
        }
      }
    }
  }

  &__button-box {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    margin-bottom: 10rem;
    @media screen and (max-width: $md) {
      margin-bottom: 7rem;
    }
    .button {
      min-width: 180px;
    }
  }

  &__catalog-box {
    display: flex;
    justify-content: space-between;
    padding-top: 3.5rem;
    @media screen and (max-width: $sm) {
      flex-direction: column;
    }
    &_container {
      width: 49%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      @media screen and (max-width: $sm) {
        width: 100%;
      }

      .button {
        min-width: 180px;
        margin-top: 2.5rem;
        @media screen and (max-width: $xs) {
          margin-top: 3rem;
        }
      }
    }
    &_title {
      font-size: 5.2rem;
      line-height: 1.25;
      margin-bottom: 14px;
      @media screen and (max-width: $md) {
        font-size: 35px;
      }
    }
    &_image {
      height: 65rem;
      width: 65rem;
      background-size: cover;
      background-position: center;
      @media screen and (max-width: $xl) {
        height: 55rem;
        width: 55rem;
      }
      @media screen and (max-width: $lg) {
        height: 45rem;
        width: 45rem;
      }
      @media screen and (max-width: $md) {
        height: 35rem;
        width: 35rem;
      }
      @media screen and (max-width: $sm) {
        margin-top: 50px;
      }
      @media screen and (max-width: $xs) {
        height: 25rem;
        width: 100%;
      }
    }
  }

  &__sponsors-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 17.6rem;
    @media screen and (max-width: $lg) {
      justify-content: flex-start;
      margin-top: 15rem;
    }
    @media screen and (max-width: $md) {
      margin-top: 10rem;
    }
    @media screen and (max-width: $xs) {
      margin-top: 7.5rem;
    }

    .title {
      font-size: 4.65rem;
      line-height: 1.3;
      margin-bottom: 35px;
      @media screen and (max-width: $xs) {
        margin-bottom: 40px;
      }
    }

    &_sponsors {
      @media screen and (max-width: $md) {
        margin-bottom: 4rem;
      }

      .container {
        width: calc(100% + 30px);
        margin-left: -15px;
        display: flex;
        // align-items: center;
        flex-wrap: wrap;

        &__item {
          max-width: 23.4rem;
          margin: 0 15px 30px;
          // &_logo {
          //   height: 100%;
          //   width: 100%;
          //   border-radius: 17px;
          //   object-fit: cover;
          // }
          &_box {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 23.4rem;
            width: 23.4rem;
            border-radius: 17px;
            overflow: hidden;
            cursor: pointer;
            text-decoration: none;
            border: 1px solid $white;
            margin-bottom: 10px;
          }
        }
      }
      .sponsor-link {
        text-decoration: none;
        font-size: 18px;
        &--no-clickable {
          cursor: default;
        }
      }
    }

    &_contacts {
      .item-title,
      .item-contact {
        font-weight: 500;
      }
      .item-contact {
        margin-left: 18px;
      }
    }
  }
}
</style>

