<template>
  <!-- 'form-control--symbol-percentage': percentage, -->
  <div
    class="form-control"
    :class="{
      'form-control--icon': icon,
      'form-control--invalid': validateError,
      'form-control--symbol': symbol,
    }"
  >
    <div class="form-control_relative">
      <div class="form-control__input_wrapper">
        <span class="form-control__symbol" v-if="symbol && (inFocus || item)">{{ symbol }}</span>
        <input
          required
          :type="type"
          class="form-control__input"
          :class="{
            'form-control__input--symbol': symbol && (inFocus || item),
          }"
          v-model="item"
          :placeholder="placeholder"
          :disabled="disabled"
          @focus="inFocus = true"
          @blur="inFocus = false"
        />
        <label class="form-control__label">
          {{ placeholder }}
          <span class="form-control__label_required" v-if="required">*</span>
        </label>
      </div>
      <div v-if="icon" class="form-control__icon">
        <slot name="icon"></slot>
        <slot name="btn"></slot>
      </div>
    </div>
    <slot name="text"></slot>
    <p class="form-control__error">{{ validateError }}</p>
  </div>
</template>
<script>
export default {
  props: {
    element: { type: [String, Number] },
    placeholder: { type: String },
    type: { type: String, default: "text" },
    icon: { type: Boolean, default: false },
    required: { type: [Boolean, Number], default: false },
    validateError: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    numberInteger: { type: Boolean, default: true },
    startWithNull: { type: Boolean, default: false },
    symbol: { type: String, required: false },
    percentage: { type: Boolean, default: false },
  },
  data() {
    return {
      item: "",
      inFocus: false,
    };
  },
  // computed: {
  //   percentage() {
  //     return this.symbol === "%";
  //   },
  // },
  created() {
    this.item = this.element;
  },
  watch: {
    element(newVal, oldVal) {
      this.item = newVal;
      this.validation(newVal);
    },
    item(newVal, oldVal) {
      if (newVal != oldVal) {
        this.validation(newVal);
      }
    },
  },
  methods: {
    validation(val) {
      let item = val;

      if (!this.numberInteger) {
        item = String(item).replace(/[^\d\.]/g, "");
        this.item = item;

        if (this.percentage && item !== "") {
          this.item = `${item}%`;
        }
      } else {
        item = String(item).replace(/[^\d]/g, "");
        this.item = item;
      }

      if (!this.startWithNull && item && +item[0] === 0) {
        item = item.substr(1);
      }
      this.$emit("input", item);
    },
  },
};
</script>
<style lang="scss" scoped>
.form-control {
  position: relative;
  margin-top: 10px;
  &_relative {
    position: relative;
  }
  &__icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
  &__label {
    width: calc(100% - 5.1rem);
    position: absolute;
    @include type($fw: 500);
    color: $lightBlack;
    top: 50%;
    left: 2.4rem;
    transform: translateY(-50%);
    padding: 1px 1.1rem;
    background-color: $white;
    transition: all 0.1s ease;
    pointer-events: none;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }
    &_required {
      font-size: 18px;
      font-weight: 600;
      color: $error;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
    }
  }
  &__input {
    height: 9rem;
    padding: 0 2.4rem 0 2.7rem;
    border: 0.5px solid $lightGrey;
    border-radius: 12px;
    @include type();
    color: $lightBlack;
    &:-webkit-autofill {
      background-color: rgba(255, 255, 255, 1) !important;
      background-image: none !important;
      -webkit-box-shadow: 0 0 0px 10em white inset !important;
      -webkit-text-fill-color: $black !important;
    }
    &::placeholder {
      color: $white;
      opacity: 0;
    }
    &:-ms-input-placeholder {
      color: $white;
      opacity: 0;
    }
    &::-ms-input-placeholder {
      color: $white;
      opacity: 0;
    }
    &:disabled {
      opacity: 0.6;
    }
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      height: 7rem;
      font-size: 14px;
    }
  }
  &__input:focus ~ &__label,
  &__input:valid ~ &__label,
  &__input:not(:placeholder-shown) ~ &__label {
    top: 0;
    width: auto;
  }
  &--symbol {
    .form-control__input {
      padding-left: 3.8rem;
    }
    .form-control__symbol {
      position: absolute;
      top: 50%;
      left: 2.7rem;
      transform: translateY(-50%);
      @include type();
      color: $lightBlack;
    }
    // &-percentage {
    //   .form-control__input {
    //     padding-left: 4rem;
    //   }
    //   .form-control__symbol {
    //     left: 2.3rem;
    //   }
    // }
  }
  &--xxs {
    .form-control__input {
      height: 6rem;
      border-radius: 8px;
      padding: 0 20px 0 10px;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        height: 5rem;
        font-size: 14px;
      }
    }
    .form-control__error {
      font-size: 12px;
    }
    &.form-control--symbol {
      .form-control__input {
        padding-left: 20px;
      }
      .form-control__symbol {
        top: 50%;
        left: 10px;
      }
    }
    .form-control__label {
      font-size: 14px;
      left: 10px;
    }
  }
  &__simple-text {
    display: inline-block;
    padding: 7px 0 0;
    @include type($fs: 13px, $fw: 600);
    //color: $greyDark;
    color: $green;
  }
  &__error {
    position: absolute;
    @include type($fs: 12px, $fw: 500);
    color: $error;
    padding-top: 3px;
    width: max-content;
  }
  &--icon {
    .form-control__input {
      padding-right: 7rem;
    }
    .form-control__label {
      width: calc(100% - 9.7rem);
    }
    &-15 {
      .form-control__input {
        padding-right: 15rem;
      }
    }
  }
  &--invalid {
    .form-control__input {
      border-color: $error;
    }
  }
  &--valid {
    .form-control__input {
      border-color: $green;
    }
  }
  &--simple {
    &.form-control--xxs.form-control--symbol .form-control__symbol {
      top: initial;
      left: 10px;
      bottom: 0;
      color: $white;
      font-size: 14px;
    }
    .form-control__label {
      position: relative;
      display: block;
      width: auto;
      top: initial;
      left: initial;
      transform: initial;
      background: transparent;
      color: $white;
      padding: 0;
      margin-bottom: 13px;
      font-family: $fontFrutiger;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.3;
      &_required {
        font-family: $fontFrutiger;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.3;
        color: $white;
      }
    }
    .form-control__input {
      height: 6.5rem;
      font-family: $fontFrutiger;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.3;
      color: $white;
      border-radius: 4px;
      background-color: rgba($color: $white, $alpha: 0.1);
      &_wrapper {
        display: flex;
        flex-direction: column-reverse;
      }
      &::-moz-placeholder {
        color: $white;
        opacity: 1;
      }
      &::-ms-input-placeholder {
        color: $white;
        opacity: 1;
      }
      &::-webkit-input-placeholder {
        color: $white;
        opacity: 1;
      }
    }
    &.form-control--xxs {
      .form-control__input {
        height: 35px;
      }
    }
  }
  &--rectangular-border {
    &-left {
      .form-control__input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    &-right {
      .form-control__input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}
</style>
